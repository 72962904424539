import Event from './utils/event';

export default new class GameScaleManager {
  constructor() {
    this.defaultCanvasSize = { H: { width: 1214, height: 594 }, V: { width: 898, height: 718 } };
    this.defaultScreenSize = { H: { width: 1920, height: 1080 }, V: { width: 918, height: 1600 } };
    this.onResize = new Event();
    this.onChangeOrientation = new Event();
  }

  init() {
    window.OPWrapperService.ScaleManager = this;
    const listener = this._onResize.bind(this);

    visualViewport.addEventListener('resize', listener);
    document.addEventListener('fullscreenchange', listener);
    /* Firefox */
    document.addEventListener('mozfullscreenchange', listener);
    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', listener);
    /* IE / Edge */
    document.addEventListener('msfullscreenchange', listener);
    listener();
  }

  set resizeBlocked(value) {
    this._resizeBlocked = value;
  }

  get resizeBlocked() {
    return this._resizeBlocked;
  }

  _onResize() {
    let newInnerWidth = visualViewport.width;
    let newInnerHeight = visualViewport.height;

    if ((this.innerWidth === newInnerWidth
      && this.innerHeight === newInnerHeight) || this._resizeBlocked) return;

    this.innerWidth = newInnerWidth;
    this.innerHeight = newInnerHeight;

    const orientation = newInnerWidth > newInnerHeight ? 'H' : 'V';

    const width = this.defaultCanvasSize[orientation].width;
    const height = this.defaultCanvasSize[orientation].height;

    const data = {
      orientation: orientation,
      innerWidth: this.innerWidth,
      innerHeight: this.innerHeight,
      gameWidth: width,
      gameHeight: height,
      safeScreenWidth: this.defaultScreenSize[orientation].width,
      safeScreenHeight: this.defaultScreenSize[orientation].height,
    };

    this.safeZone = {
      width: data.safeScreenWidth,
      height: data.safeScreenHeight,
    }

    this.onResize.call(data);
    window.OPWrapperService.eventManager.dispatch(window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE, data);
    if (orientation !== this.orientation) {
      console.log(`Orientation:${orientation}`);
      this.orientation = orientation;
      this.onChangeOrientation.call(data);
    }
  }
};
